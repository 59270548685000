/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable camelcase */
import vietnamTickets from './vietnamTickets/index';

// Thay đổi sang import cả object từ folder
const DATA_TEMPLATES = {
  ...vietnamTickets,
};
export default DATA_TEMPLATES;
