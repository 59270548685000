/* eslint-disable camelcase */
import dynamic from 'next/dynamic';



// const templateLayout_68 = dynamic(() => import(`./layouts/templateLayout_68`), {
//   ssr: true,
//   loading: () => null
// });
const templateLayout_6 = dynamic(() => import(`./layouts/templateLayout_6`), {
  ssr: true,
  loading: () => null
});
const templateLayout_2 = dynamic(() => import(`./layouts/templateLayout_2`), {
  ssr: true,
  loading: () => null
});
const templateLayout_34 = dynamic(() => import(`./layouts/templateLayout_34`), {
  ssr: true,
  loading: () => null
});
const templateLayout_4 = dynamic(() => import(`./layouts/templateLayout_4`), {
  ssr: true,
  loading: () => null
});
const templateLayout_3 = dynamic(() => import(`./layouts/templateLayout_3`), {
  ssr: true,
  loading: () => null
});
const templateLayout_1 = dynamic(() => import(`./layouts/templateLayout_1`), {
  ssr: true,
  loading: () => null
});
const templateLayout_10 = dynamic(() => import(`./layouts/templateLayout_10`), {
  ssr: true,
  loading: () => null
});
const templateLayout_41 = dynamic(() => import(`./layouts/templateLayout_41`), {
  ssr: true,
  loading: () => null
});
const templateLayout_42 = dynamic(() => import(`./layouts/templateLayout_42`), {
  ssr: true,
  loading: () => null
});
const templateLayout_43 = dynamic(() => import(`./layouts/templateLayout_43`), {
  ssr: true,
  loading: () => null
});
const templateLayout_44 = dynamic(() => import(`./layouts/templateLayout_44`), {
  ssr: true,
  loading: () => null
});
const templateLayout_45 = dynamic(() => import(`./layouts/templateLayout_45`), {
  ssr: true,
  loading: () => null
});
const templateLayout_46 = dynamic(() => import(`./layouts/templateLayout_46`), {
  ssr: true,
  loading: () => null
});
const templateLayout_47 = dynamic(() => import(`./layouts/templateLayout_47`), {
  ssr: true,
  loading: () => null
});
const templateLayout_51 = dynamic(() => import(`./layouts/templateLayout_51`), {
  ssr: true,
  loading: () => null
});
const templateLayout_52 = dynamic(() => import(`./layouts/templateLayout_52`), {
  ssr: true,
  loading: () => null
});
const templateLayout_53 = dynamic(() => import(`./layouts/templateLayout_53`), {
  ssr: true,
  loading: () => null
});
const templateLayout_56 = dynamic(() => import(`./layouts/templateLayout_56`), {
  ssr: true,
  loading: () => null
});
const templateLayout_57 = dynamic(() => import(`./layouts/templateLayout_57`), {
  ssr: true,
  loading: () => null
});
const templateLayout_58 = dynamic(() => import(`./layouts/templateLayout_58`), {
  ssr: true,
  loading: () => null
});
const templateLayout_59 = dynamic(() => import(`./layouts/templateLayout_59`), {
  ssr: true,
  loading: () => null
});
const templateLayout_60 = dynamic(() => import(`./layouts/templateLayout_60`), {
  ssr: true,
  loading: () => null
});
const templateLayout_61 = dynamic(() => import(`./layouts/templateLayout_61`), {
  ssr: true,
  loading: () => null
});
// const product = dynamic(() => import(`./layouts/product`), {
//   ssr: true,
//   loading: () => null,
// });
// const search = dynamic(() => import(`./layouts/search`), {
//   ssr: true,
//   loading: () => null,
// });




// Đặt tên key theo đúng định dạng: [folder]_[file]
// const folder = "template_antshoes";

const folder = "vietnamTickets_";
export default {
  // [`${folder}templateLayout_68`]: templateLayout_68,
  [`${folder}templateLayout_6`]: templateLayout_6,
  [`${folder}templateLayout_2`]: templateLayout_2,
  [`${folder}templateLayout_34`]: templateLayout_34,
  [`${folder}templateLayout_4`]: templateLayout_4,
  [`${folder}templateLayout_3`]: templateLayout_3,
  [`${folder}templateLayout_1`]: templateLayout_1,
  [`${folder}templateLayout_10`]: templateLayout_10,
  [`${folder}templateLayout_41`]: templateLayout_41,
  [`${folder}templateLayout_42`]: templateLayout_42,
  [`${folder}templateLayout_43`]: templateLayout_43,
  [`${folder}templateLayout_44`]: templateLayout_44,
  [`${folder}templateLayout_45`]: templateLayout_45,
  [`${folder}templateLayout_46`]: templateLayout_46,
  [`${folder}templateLayout_47`]: templateLayout_47,
  [`${folder}templateLayout_51`]: templateLayout_51,
  [`${folder}templateLayout_52`]: templateLayout_52,
  [`${folder}templateLayout_53`]: templateLayout_53,
  [`${folder}templateLayout_56`]: templateLayout_56,
  [`${folder}templateLayout_57`]: templateLayout_57,
  [`${folder}templateLayout_58`]: templateLayout_58,
  [`${folder}templateLayout_59`]: templateLayout_59,
  [`${folder}templateLayout_60`]: templateLayout_60,
  [`${folder}templateLayout_61`]: templateLayout_61,
}

